import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";

// typings

// components
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import NewServicesSelect from "~/components/Nurses/NewServicesSelect";
import { medicalActionAPI, nurseAPI } from "~/utils/api/v2";
import { listMedicalServices } from "~/utils/api/v2/items";
import { Locale } from "~/utils/interfaces/Locale";
import { MedicalActions } from "~/utils/interfaces/models";
import { NurseName } from "~/utils/interfaces/Nurse";
import { Item } from "~/utils/interfaces/Services";

interface ExamsProps {
  id: string;
  location: {
    state: {
      id: string;
      names: string;
      last_names: string;
      country: Locale;
    };
  };
}

function Exams({ location }: ExamsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});

  const [nurseData, setNurseData] = useState<NurseName | undefined>();

  const state = location.state;
  const [medicalServices, setMedicalServices] = useState<Item[]>([]);
  const [medicalActions, setMedicalActions] = useState<MedicalActions[]>([]);
  const [selectedMedicalServices, setSelectedMedicalServices] = useState<Item[]>([]);
  const [selectedMedicalActions, setSelectedMedicalActions] = useState<MedicalActions[]>([]);

  useEffect(() => {
    if (!state) {
      navigate("/");
      return;
    }
    setNurseData(state);

    const fetchNewServices = async () => {
      setLoading(true);
      try {
        const [medicalServices, medicalActions, selectedMedicalServices, selectedMedicalActions] = await Promise.all([
          listMedicalServices(state.country),
          medicalActionAPI.list(state.country),
          nurseAPI.listMedicalServices(state.id),
          nurseAPI.listMedicalActions(state.id),
        ]);
        setMedicalServices(medicalServices || []);
        setMedicalActions(medicalActions.data);
        setSelectedMedicalServices(selectedMedicalServices.data);
        setSelectedMedicalActions(selectedMedicalActions.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchNewServices();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && nurseData && (
        <>
          <h2>
            Detalle de exámenes {nurseData.names} {nurseData.last_names}
          </h2>
          <div className="p-12 my-4 rounded-lg bg-white border border-gray-200 flex flex-col">
            <NewServicesSelect
              state={state}
              setLoading={setLoading}
              loading={loading}
              setError={setError}
              medicalServices={medicalServices}
              medicalActions={medicalActions}
              selectedMedicalServices={selectedMedicalServices}
              setSelectedMedicalServices={setSelectedMedicalServices}
              selectedMedicalActions={selectedMedicalActions}
              setSelectedMedicalActions={setSelectedMedicalActions}
            />
          </div>
        </>
      )}
    </PrivateRoute>
  );
}

export default Exams;
